/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { MUIDataTableColumnDef, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import MuiTable from 'components/molecules/MuiTable';
import AdminTemplate from 'components/templates/AdminTemplate';
import { Pagination } from 'interfaces/pagination.interface';
import useProducts from 'hooks/useProducts';
import useWarehouses from 'hooks/useWarehouse';
import textLabels from 'utils/MUIDataTableTextLabels';
import { useProductsContext } from 'contextApi/ProductsContext';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { IWarehouse } from 'interfaces/warehouse.interface';
import { WCIProduct } from 'interfaces/product.interface';

export default function Products() {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<WCIProduct[]>([]);
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [cityCode, setCityCode] = useState<string>('');
  const [warehouseId, setWarehouseId] = useState('');
  const [validateField, setValidateField] = useState({
    haveErrors: false,
    messageError: '',
  });
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { getProducts, saveWarehouse } = useProducts();
  const { getWarehouses } = useWarehouses();
  const [searchProduct, setSearchProduct] = useState('');
  const { updatePagination, pagination } = useProductsContext();
  const { showNotistack } = useCustomSnackbar();

  useEffect(() => {
    setRowsPerPage(pagination.limit);
  }, []);

  const handleGetWarehouses = () => {
    getWarehouses()
      .then((warehousesResponse) => {
        setWarehouses(warehousesResponse);
      })
      .catch(console.log);
  };

  const handleGetProducts = () => {
    setIsLoading(true);
    const page = searchProduct === '' ? pagination.page + 1 : 1;
    getProducts({ ...pagination, page, name: searchProduct })
      .then((productsResponse) => {
        setProducts(productsResponse.data);
        setTotalOrders(productsResponse.total);
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAssignWarehouses = () => {
    if (cityCode === '') {
      setValidateField({
        haveErrors: true,
        messageError: 'Campo obligatorio',
      });
      return;
    }
    setIsLoading(true);
    saveWarehouse(selectedProducts, cityCode, warehouseId)
      .then(() => {
        handleGetProducts();
        showNotistack({
          message: `La bodega de origen se ha asignado correctamente a los productos ${selectedProducts.length} seleccionados.`,
        });
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const CustomToolbar = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 10 }}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          value={searchProduct}
          label="Buscar por nombre"
          onChange={({ target: { value } }) => setSearchProduct(value)}
        />
        <Box>
          <Button variant="contained" color="primary" onClick={handleGetProducts}>
            Buscar
          </Button>
        </Box>
      </Box>
    );
  };

  const customToolbarOnItemsSelected = () => {
    return (
      <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <Grid item xs={6} sm={4}>
          <TextField
            name="cityCode"
            fullWidth
            select
            margin="dense"
            variant="outlined"
            label="Bodega de origen"
            SelectProps={{
              native: true,
            }}
            error={validateField.haveErrors}
            onChange={(e) => {
              const warehouseSelected = warehouses.find(
                (warehouse) => warehouse.id === e.target.value
              );
              setWarehouseId(warehouseSelected?.id || '');

              setCityCode(warehouseSelected?.cityCode || '');
              if (e.target.value !== '') {
                setValidateField({
                  haveErrors: false,
                  messageError: '',
                });
              }
            }}
            helperText={validateField?.messageError}
            InputLabelProps={{ shrink: true }}
          >
            <option>Selecciona una bodega</option>
            {warehouses.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Tooltip title="Asignar bodega a productos seleccionados.">
            <Button variant="contained" color="primary" onClick={handleAssignWarehouses}>
              Asignar bodega
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const options: MUIDataTableOptions = {
    page: pagination.page,
    print: false,
    filter: false,
    count: totalOrders,
    serverSide: true,
    viewColumns: false,
    download: false,
    rowHover: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [20, 50, 100],
    textLabels,
    search: false,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    onRowSelectionChange: (_, allRowsSelected, rowsSelected) => {
      console.log('rowsSelected', rowsSelected);

      const productsSelected: number[] = [];
      rowsSelected?.map((row) => {
        productsSelected.push(products[row].id);
      });
      setSelectedProducts(productsSelected);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      updatePagination({ ...pagination, page: 0, limit: numberOfRows });
      setRowsPerPage(numberOfRows);
      getProducts({ ...pagination, page: 1, limit: numberOfRows, name: searchProduct });
    },
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      console.log('action', action);
      if (action === 'changePage') {
        const newPagination: Pagination = {
          ...pagination,
          page: tableState.page,
          limit: tableState.rowsPerPage,
        };
        updatePagination({ ...newPagination });
      }
    },
    customToolbarSelect: customToolbarOnItemsSelected,
    customToolbar: CustomToolbar,
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: 'Producto',
    },
    {
      name: 'sku',
      label: 'SKU',
    },
    {
      name: 'regular_price',
      label: 'Precio',
    },
    {
      name: 'meta_data',
      label: 'Bodega de Origen',
      options: {
        customBodyRender: (value) => {
          console.log(
            '----',
            value.find((meta: any) => meta.key === '_coordinadora_warehouse_id')?.value
          );

          if (Array.isArray(value)) {
            const warehouseToAssignById = warehouses.find(
              (warehouse) =>
                warehouse.id ===
                value.find((meta) => meta.key === '_coordinadora_warehouse_id')?.value
            )?.name;
            const warehouseToAssginByCityCode = warehouses.find(
              (warehouse) =>
                warehouse.cityCode ===
                value.find((meta) => meta.key === '_coordinadora_warehouse')?.value
            )?.name;
            return warehouseToAssignById || warehouseToAssginByCityCode || 'Sin asignar';
          }
        },
      },
    },
  ];

  useEffect(() => {
    handleGetWarehouses();
  }, []);

  useEffect(() => {
    handleGetProducts();
  }, [pagination]);
  console.log('products', products);

  return (
    <AdminTemplate>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" component="h1" align="center">
            Productos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <MuiTable
            title="Lista de productos"
            isLoading={isLoading}
            columns={columns}
            data={products}
            options={options}
          />
        </Grid>
      </Grid>
    </AdminTemplate>
  );
}
