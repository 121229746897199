import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import localeEs from 'date-fns/locale/es';
import { Button, Grid, Link, Typography } from '@material-ui/core';
import {
  MUIDataTableColumnDef,
  MUIDataTableMeta,
  MUIDataTableOptions,
  MUIDataTableState,
} from 'mui-datatables';
import MuiTable from 'components/molecules/MuiTable';
import AdminTemplate from 'components/templates/AdminTemplate';
import { Pagination } from 'interfaces/pagination.interface';
import { IOrder, IOrderToShipping, OrdersFiltersData } from 'interfaces/order.interface';
import useOrders from 'hooks/useOrders';
import textLabels from 'utils/MUIDataTableTextLabels';
import { useOrdersContext } from 'contextApi/OrdersContext';
import CreateShippingModal from 'components/organisms/CreateShippingModal';
import OrdersFilters from './OrdersFilters';
import OrdersCustomToolbarSelect from '../../organisms/OrdersCustomToolbarSelect';
import CreateMultipleShippingModal from 'components/organisms/CreateMultipleShippingModal';
import { subDays } from 'date-fns';

const nowDate = new Date();

const defaultFilters: OrdersFiltersData = {
  startDate: subDays(nowDate, 15),
  endDate: nowDate,
  orderId: '',
  trackingNumber: '',
};

export default function Orders() {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const { getOrders } = useOrders();
  const { setOrderToShipping, updatePagination, pagination, openModalCreateShipping } =
    useOrdersContext();
  const [ordersSelected, setOrdersSelected] = useState<IOrder[]>([]);
  const [indexItemsSelected, setIndexItemsSelected] = useState<number[]>([]);

  const [openCreateMultipleShippingModal, setOpenCreateMultipleShippingModal] = useState(false);

  const [filters, setFilters] = useState<OrdersFiltersData>(defaultFilters);

  useEffect(() => {
    setRowsPerPage(pagination.limit);
  }, []);

  const createShipping = (rowIndex: number) => {
    const order = orders[rowIndex];
    const orderToShipping = {
      orderId: order.id,
      orderNumber: order.id,
      recipientsName: `${order.shipping.first_name} ${order.shipping.last_name}`,
      recipientsProvinceName: order.shipping.state,
      recipientsCityName: order.shipping.city,
      products: order.line_items,
      totalPrice: Number(order.total),
      recipientsAddress: `${order.shipping.address_1} ${order.shipping.address_2}`,
      name: `#${order.id.toString()}`,
      paymentGateway: order.payment_method_title,
    } as IOrderToShipping;

    setOrderToShipping(orderToShipping);
  };

  const handleGetOrders = (newFitlers?: OrdersFiltersData) => {
    setIsLoading(true);
    setOrdersSelected([]);
    setIndexItemsSelected([]);
    if (newFitlers) {
      setFilters(newFitlers);
      pagination.page = 0;
    }

    getOrders({ ...pagination, page: pagination.page + 1 }, newFitlers || filters)
      .then((ordersResponse) => {
        setOrders(ordersResponse.data);
        setTotalOrders(ordersResponse.total);
      })
      .catch(() => {
        // TODO redireccionar en 403
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const options: MUIDataTableOptions = {
    page: pagination.page,
    print: false,
    filter: false,
    count: totalOrders,
    serverSide: true,
    viewColumns: false,
    download: false,
    rowHover: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [20, 50, 100, 200],
    textLabels,
    search: false,
    selectableRows: 'multiple',
    selectableRowsOnClick: false,
    onChangeRowsPerPage: (numberOfRows: number) => {
      updatePagination({ ...pagination, page: 0, limit: numberOfRows });
      setRowsPerPage(numberOfRows);
      getOrders({ ...pagination, page: 1, limit: numberOfRows }, filters)
        .then((ordersResponse) => {
          setOrders(ordersResponse.data);
          setTotalOrders(ordersResponse.total);
        })
        .catch(() => {
          // TODO redireccionar en 403
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    rowsSelected: indexItemsSelected,
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, ordersSelectedFromTable) => {
      if (ordersSelectedFromTable) {
        setOrdersSelected(ordersSelectedFromTable.map((i) => orders[i]));
        setIndexItemsSelected(ordersSelectedFromTable);
      }
    },
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      if (action === 'changePage') {
        const newPagination: Pagination = {
          ...pagination,
          page: tableState.page,
          limit: tableState.rowsPerPage,
        };
        updatePagination({ ...newPagination });
        getOrders(
          { ...pagination, page: tableState.page + 1, limit: tableState.rowsPerPage },
          filters
        )
          .then((ordersResponse) => {
            setOrders(ordersResponse.data);
            setTotalOrders(ordersResponse.total);
          })
          .catch(() => {
            // TODO redireccionar en 403
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    customToolbarSelect: () => (
      <OrdersCustomToolbarSelect
        ordersSelected={ordersSelected}
        setOpenCreateMultipleShippingModal={setOpenCreateMultipleShippingModal}
      />
    ),
  };

  const columns: MUIDataTableColumnDef[] = [
    // 0 id -> Pedido
    {
      name: 'id',
      label: 'Pedido',
    },
    // 1 shipping -> Destinatario
    {
      name: 'shipping',
      label: 'Destinatario',
      options: {
        customBodyRender: (value) => {
          return `${value.first_name} ${value.last_name}`;
        },
      },
    },
    // 2 date_created -> Fecha del pedido
    {
      name: 'date_created',
      label: 'Fecha del pedido',
      options: {
        customBodyRender: (value) => {
          if (!value) return '';
          return format(
            parse(value.substring(0, 19).replace('T', ' '), 'yyyy-MM-dd HH:mm:ss', new Date()),
            "dd MMMM 'a las' HH:mm",
            {
              locale: localeEs,
            }
          );
        },
      },
    },
    // 3 status -> Estado del pedido
    {
      name: 'status',
      label: 'Estado del pedido',
      options: {
        customBodyRender: (value) => {
          switch (value) {
            case 'pending':
              return 'Pendiente';
            case 'processing':
              return 'Procesando';
            case 'on-hold':
              return 'En espera';
            case 'refunded':
              return 'Reembolsado';
            case 'completed':
              return 'Completado';
            case 'cancelled':
              return 'Cancelado';
            default:
              return value;
          }
        },
      },
    },
    // 4 shipping -> Destino
    {
      name: 'shipping',
      label: 'Destino',
      options: {
        customBodyRender: (value) => {
          return `${value.city}`;
        },
      },
    },
    // 5 meta_data -> Guía
    {
      name: 'meta_data',
      label: 'Guía',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        customBodyRender: (value, tableMeta: MUIDataTableMeta) => {
          const order = orders[tableMeta.rowIndex];

          if (order.status === 'cancelled') {
            return '-';
          } else if (Array.isArray(value)) {
            const metaTrackingNumber = value.find(
              (meta) => meta.key === '_coordinadora_tracking_number'
            );
            const metaTrackingUrl = value.find((meta) => meta.key === '_coordinadora_tracking_url');
            if (
              metaTrackingNumber &&
              metaTrackingNumber.value &&
              metaTrackingUrl &&
              metaTrackingUrl.value
            ) {
              return (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(`${metaTrackingUrl.value}`);
                  }}
                >
                  {metaTrackingNumber.value}
                </Link>
              );
            }
            return (
              <Grid container>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={async () => {
                    createShipping(tableMeta.rowIndex);
                  }}
                >
                  Crear
                </Button>
              </Grid>
            );
          } else {
            return 'valor incorrecto';
          }
        },
      },
    },
  ];

  return (
    <AdminTemplate>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" component="h1" align="center">
            Generación de guías
          </Typography>
        </Grid>
        <OrdersFilters handleGetOrders={handleGetOrders} />
        <Grid item xs={12} sm={12}>
          <MuiTable
            title="Lista de pedidos"
            isLoading={isLoading}
            columns={columns}
            data={orders}
            options={options}
          />
        </Grid>
      </Grid>
      {openModalCreateShipping && <CreateShippingModal getOrders={handleGetOrders} />}
      {openCreateMultipleShippingModal && (
        <CreateMultipleShippingModal
          getOrders={handleGetOrders}
          orders={ordersSelected.filter(
            (o) => !o.meta_data.some((m) => m.key === '_coordinadora_tracking_number' && m.value)
          )}
          openModal={openCreateMultipleShippingModal}
          closeModal={() => setOpenCreateMultipleShippingModal(false)}
        />
      )}
    </AdminTemplate>
  );
}
